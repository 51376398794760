<template>

    <div class="container mt-3">

        <div class="header text-center text-md-start">

            <h3>
                <i class="bi bi-book"></i>
                {{ $t('titles.menuList') }}
            </h3>

            <DisplayCurrentWeek
                :currentDate="state.date"
            />

        </div>

<!--
        <NavigationWeeks
            :newDate="state.date"
            :weekdaysToDisplay="state.weekdaysToDisplay"
            @onWeekdaysToDisplayChange="(n) => onDaysToDisplayChange(n)"
            @onDateChange="(newDate) => onDateChange(newDate)"
        />
-->



        <NavigationWeeks
            :newDate="state.date"
            :weekdaysToDisplay="state.weekdaysToDisplay"
            @onWeekdaysToDisplayChange="(n) => state.weekdaysToDisplay = n"
            @onDateChange="(newDate) => onDateChange(newDate)"
        />




<!--
        <div class="row my-3">

            <div class="col">
                <button
                    v-if="masterUser()"
                    type="button"
                    tag="button"
                    class="btn btn-outline-primary btn-sm mx-3"
                    @click.prevent="createResource()"
                  >
                    <i class="bi bi-pencil-square"></i>
                    {{ $t('form.actions.newMenu') }}
                </button>
            </div>
        </div>
-->


        <div class="d-flex justify-content-center">
            <div v-show="useMenus.state.form.loading" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

    </div>




        <!-- ************************************************************** -->
        <!-- Display menus -->
        <!-- ************************************************************** -->

    <div class="container mt-3">
        <div
            v-if="!useMenus.state.form.loading"
        >

            <div class="table-responsive mt-5">
                <table class="table align-middle">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">Date</th>
                            <th scope="col">Title</th>

                            <th scope="col">Save</th>
                            <th scope="col">Delete</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr
                            v-for="item, index in useMenus.state.form.menusOfTheWeek"
                            v-bind:key="item"


                            :class="item.id ? 'bg-secondary p-2 text-dark bg-opacity-10' : ''"
                        >

                            <template v-if="index < state.weekdaysToDisplay">



                                <td>{{ item.id }}</td>
                                <td class="text-nowrap">{{ formatedDate(item.date) }}</td>
                                <td>
                                    <div class="input-group" style="min-width: 500px;">
                                        <input
                                            type="text"
                                            class="form-control rounded-4 my-2 me-3"
                                            v-bind:class="checkItemChange(item.title, useMenus.state.form.copyMenusOfTheWeek[index].title) ? '' : 'is-invalid bg-danger text-dark bg-opacity-10'"
                                            @keyup.enter="saveResource(item)"
                                            placeholder="menu..."
                                            v-model="item.title"
                                        >
                                    </div>
                                </td>


                                <td>
                                    <a
                                        v-if="item.title"
                                        class="icon-edit fs-4 me-5"
                                        href="#"
                                        @click.prevent="saveResource(item)"
                                    >
                                        <i class="bi bi-save"></i>
                                    </a>
                                </td>

                                <td>
                                    <a
                                        v-if="item.id"
                                        class="icon-edit fs-4"
                                        href="#"
                                        @click.prevent="deleteResource(item)"
                                    >
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </td>

                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</template>

<script>

// import Vue.js framework functions
//import { ref } from 'vue'
import { onMounted } from 'vue'
import { reactive } from 'vue'
//import { provide } from 'vue'

// import external functionalities
//import { Modal } from 'bootstrap';

// import application's components
//import MyModal from '@/components/MyModal.vue'
import DisplayCurrentWeek from '@/components/dates/DisplayCurrentWeek.vue'
import NavigationWeeks from '@/components/dates/NavigationWeeks.vue'

// import Datepicker
//import Datepicker from 'vue3-date-time-picker';
//import 'vue3-date-time-picker/dist/main.css'


// import composables files
import useAuthComp from '@/composables/useAuthComp.js'
import useMenusComp from '@/composables/useMenusComp.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'

import { useI18n } from 'vue-i18n'

// import informations and errors messages components
//import AltertMessage from '@/components/messages/altertMessage.vue'
//import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
//import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'menus',

    components: {
        //MyModal,
        //Datepicker,
        //ValidationErrors,
        //ExceptionMessage,
        DisplayCurrentWeek,
        NavigationWeeks,
    },

    setup() {

        const { masterUser } = useAuthComp()

        const useMenus = useMenusComp()
        const formUtilsComp = useFormUtilsComp()
        const useDateTimeUtils = useDateTimeUtilsComp()
        const ojectsUtils = useObjectsUtils()

        // Variable needed to access the reference of the QuillEditor
        // instance and use its methods
        //const myQuillEditorRef = ref()
        const { t, locale } = useI18n({ useScope: 'global' })




        const state = reactive({
            date: new Date(),
            weekdaysToDisplay: 5,

            modal: {
                edit: 'editMenuModal',
                show: 'showMenuModal',
                alert: 'alertModal',
                header: '',
                action: '',
                componentEdit: null,
                componentShow: null,
                componentAlert: null
            },
            week: [
                1,2,3,4,5,6,7
            ],
            menusOfTheWeek: [],
            copyMenusOfTheWeek: [],

        });


        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`
        }


        onMounted(() => {
            //state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))

            //getAllMenus()
            getMenus()
        })


        function getMenus() {

            //console.log(state.date)
            //console.log(useDateTimeUtils.formatIsoDate(useDateTimeUtils.getMonday(state.date)))

            useMenus.getMenusFromDateInterval(
                {
                    start: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getMonday(state.date)),
                    end: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getSunday(state.date))
                },
                state.weekdaysToDisplay
            )
        }


        // Save new created or updated resource
        function saveResource(item) {

            useMenus.state.form.body = ojectsUtils.cloneObject(item)

            if ( useMenus.state.form.body.id == null ) {

                useMenus.storeResource()
                .then(() => {
                    // Close Modal component
                    //state.modal.componentEdit.toggle()
                    //myQuillEditorRef.value.setHTML('')
                    console.log('store ok')
                })
                .catch((error) => {
                    //formUtilsComp.useSetErrors(error.response.data)
                    useMenus.useErrors.useSetErrors(error.response.data)
                })
            } else {
                useMenus.updateResource()
                .then(() => {
                    // Close Modal component
                    //state.modal.componentEdit.toggle()
                    console.log('update ok')
                })
                .catch((error) => {
                    //formUtilsComp.useSetErrors(error.response.data)
                    useMenus.useErrors.useSetErrors(error.response.data)
                })
            }
        }

        function deleteResource(resource) {
            if ( confirm(t('form.infos.delete.confirmation') + ` '${resource.id} ${resource.date}'`) ) {
                useMenus.deleteResource(resource)
            }
        }


        function onDateChange(newDate) {
            state.date = newDate
            getMenus()
        }

        function findMenuFromDay(day) {
            var found = useMenus.state.form.resources.find(val => (new Date(val.date).getDay()) === day)
            return found
        }

        function checkItemChange(item, copy) {
            return item === copy
        }

        // Return formated date to display
        function formatedDate(date) {
            //return dateTime.formatedDate(date, state.lang, "LLLL")
            return useDateTimeUtils.formatedDate(date, locale.value, "dddd D MMMM YYYY")
        }


        return {
            state,
            format,
            useDateTimeUtils,
            useMenus,
            masterUser,
            //createResource,
            saveResource,
            deleteResource,
            formUtilsComp,
            onDateChange,
            findMenuFromDay,
            checkItemChange,
            formatedDate,
        }

    },
}


</script>


<style>
    @import '../../assets/css/logo.css';
</style>
