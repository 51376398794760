/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Users management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'
import useArrayUtilsComp from '@/composables/useArraysUtilsComp.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'

export default function useMenusComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()
    const useArrayUtils = useArrayUtilsComp()
    const useDateTimeUtils = useDateTimeUtilsComp()
    const ojectsUtils = useObjectsUtils()


    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                date: '',
                title: '',
            },
            status: null,
            resources: null,
            menusOfTheWeek: [],
            copyMenusOfTheWeek: [],
            loading: true,

            response: {
                message: null,
                alert: null,
            }
        },
    })



/* ************************************************************************ */
    const tableSort = reactive({
        asc: true,
        col: '',
    })

    // Function to sort Array of Resources
    function sortMenus(byKey, type ) {

/*
        if ( tableSort.col === byKey ) {
            tableSort.asc = !tableSort.asc
        } else {
            tableSort.asc = true
            tableSort.col = byKey
        }
*/
        //useArrayUtils.sortArray( state.form.resources, byKey, type, tableSort.asc )
        useArrayUtils.sortArrayForTables( state.form.resources, byKey, type, tableSort )
    }


/* ************************************************************************ */


    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.date = ''
        state.form.body.title = ''
    }

    // Get resources from backend
    function getMenus() {

        state.form.loading = true

        return useCrud.getAllResources({
            url: 'menus'
        })
        .then((res) => {
            state.form.resources = res.data.data

            state.form.loading = false
            state.form.status = 'success'
        })
    }

    // Get resources from backend
    function getMenusFromDateInterval(interval) {

        state.form.loading = true

        return useCrud.getResourcesFromDateInterval({
            url: 'menus/fromInterval',
            resource: interval
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'

            buildWeekMenus(res.data.data, interval.start)
        });
    }


    function buildWeekMenus(resources, monday) {


        state.form.menusOfTheWeek = []
        state.form.copyMenusOfTheWeek = []

        for (var i = 0; i < 7; i++) {

            let day = useDateTimeUtils.addDays(new Date(monday), i)

            let newObj = {}
            newObj.date = useDateTimeUtils.databaseFormatedDate(day, "YYYY-MM-DD")




            //var found = resources.find(val => (val.date === useDateTimeUtils.databaseFormatedDate(day, "YYYY-MM-DD")))
            let found = resources.find(val => (val.date === newObj.date))

            if (found === undefined || found === null) {
                newObj.id = null
                newObj.title = null

            } else {
                newObj.id = found.id
                newObj.title = found.title
            }

            state.form.menusOfTheWeek.push(newObj)
        }
        state.form.copyMenusOfTheWeek = ojectsUtils.cloneObject(state.form.menusOfTheWeek)
    }

    function updateWeekMenus(resource) {

        let date = useDateTimeUtils.databaseFormatedDate(resource.date, "YYYY-MM-DD")

        let index = state.form.menusOfTheWeek.findIndex(x => x.date === date)
        state.form.menusOfTheWeek[index] = {}
        state.form.menusOfTheWeek[index] = ojectsUtils.cloneObject(resource)

        let indexCopy = state.form.copyMenusOfTheWeek.findIndex(x => x.date === date)
        state.form.copyMenusOfTheWeek[indexCopy] = {}
        state.form.copyMenusOfTheWeek[indexCopy] = ojectsUtils.cloneObject(resource)

    }


    // Store new created resource to backend
    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'menus',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'

            updateWeekMenus(res.data.resource)
        })
    }

    // Update modified resource in backend
    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'menus/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message

            updateWeekMenus(res.data.resource)
        })
    }


    // Delete resource in backend
    function deleteResource(resource) {

        return useCrud.deleteResource({
            url: 'menus/' + resource.id,
            resource: resource.id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === resource.id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'

            let indexMenus = state.form.menusOfTheWeek.findIndex(x => x.id === resource.id)
            let indexMenusCopy = state.form.copyMenusOfTheWeek.findIndex(x => x.id === resource.id)

            state.form.menusOfTheWeek[indexMenus].id = null
            state.form.menusOfTheWeek[indexMenus].title = null

            state.form.copyMenusOfTheWeek[indexMenusCopy].id = null
            state.form.copyMenusOfTheWeek[indexMenusCopy].title = null



        })

    }




    return {
        state,
        flushAttributes,
        sortMenus,
        getMenus,
        getMenusFromDateInterval,
        storeResource,
        updateResource,
        deleteResource,
        useErrors,
    }


}
