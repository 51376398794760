<template>
    <p class="week-date">
        {{ $t('dateTime.Week') }} {{ useDateTimeUtils.getWeekNumber(useDateTimeUtils.getMonday(currentDate)) }}
        <span class="date-long">({{ useDateTimeUtils.showDate(useDateTimeUtils.getMonday(currentDate)) }} {{ $t('dateTime.to') }} {{ useDateTimeUtils.showDate(useDateTimeUtils.getSunday(currentDate)) }})</span>
    </p>
</template>


<script>

// import composables files
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'


export default {

    name: 'DisplayCurrentWeek',

    components: {

    },

    props: {
        currentDate: Date,
    },

    //setup(props) {
    setup() {

        const useDateTimeUtils = useDateTimeUtilsComp()

        /**
         * Returned data and functions to the template
         */
        return {
            useDateTimeUtils,
        }

    },

}
</script>

<style>
    @import '../../assets/css/logo.css';
</style>
